import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import Photo from "../Photo";
import NewMap from "../NewMap";
import AlertDialog from "../AlertDialog";
import AddPropertyPopup from "../../userProfile/add-property";
import RenewPlanModal from "../../Subscriptions/RenewPlanModal";
import { currencyFormatter, getObjLength } from "../../helpers/utils";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import { subscriptionAction } from "../../../redux/slices/subscription/subscriptionSlice";
import { Loader } from "../loader";

const MapSection = ({
  propertiesData = [],
  activeStep,
  handleOpenViewPropertyDetailModal,
  isSecondaryUser = false,
  handleOpenCancelSubscriptionModal,
  setSelectedProperty,
  handleOpenAddSubscriptionModal,
  cardDetails,
  getPropertyList,
  callPaginationAPI,
  isMapDisplay,
  setIsMapDisplay = () => {},
}) => {
  const dispatch = useDispatch();

  const [isRenewPlanModalOpen, setIsRenewPlanModalOpen] = useState(false);
  const [clickedRenewBtn, setClickedRenewBtn] = useState(false);
  const [openRenewPlanModal, setOpenRenewPlanModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [valdiateModal, setValdiateModal] = useState(false);
  const [retryPayment, setRetryPayment] = useState(false);
  const [isRenewFinalAmtModalOpen, setIsRenewFinalAmtModalOpen] =
    useState(false);
  const [isResumeSubscription, setIsResumeSubscription] = useState(false);

  // store data
  const {
    subscriptionLoader,
    subscriptionRenewed,
    retryPaymentData,
    resumeSubscriptionData,
  } = useSelector((state) => state?.subscription);
  const { loggedInEmail, userProfileDetails } = useSelector(
    (state) => state?.userProfile
  );
  const { totalPaymentDetails, totalPaymentLoader } = useSelector(
    (state) => state?.signUpUser
  );

  const handleGetEstimatedAmount = (row) => {
    console.log("row==>", row);

    let data;
    if (row) {
      data = {
        property_id: [row?.subscription?.property_id],
        user_id: userProfileDetails?.data?.user_id,
      };
    }
    dispatch(
      userSigupAction.getTotalPaymentDetails({
        url: `get-amount`,
        data: data,
        token: true,
      })
    );
    setIsRenewFinalAmtModalOpen(true);
  };

  const handleRenewPlanModalClose = () => {
    dispatch(subscriptionAction.clearData({}));
    setIsRenewPlanModalOpen(false);
    setOpenRenewPlanModal(false);
    setClickedRenewBtn(false);
  };

  const handleRenewPlan = (selectedCard) => {
    let subId = selectedRow?.subscription?.id;
    console.log(subId, "selectedCard==>", selectedCard, selectedCard?.[0]?.id);
    if (subId) {
      dispatch(
        subscriptionAction.getPropertiesData({
          subId,
          propertiesData,
        })
      );
      if (retryPayment) {
        dispatch(
          subscriptionAction.getRetryPaymentData({
            url: `re-payment/${subId}`,
            data: {
              card_id: selectedCard?.[0]?.id,
            },
            token: true,
          })
        );
      } else {
        dispatch(
          subscriptionAction.getSubscriptionRenewed({
            url: `customer/subscription-renew/${subId}?card_id=${selectedCard?.[0]?.id}`,
            token: true,
          })
        );
      }
    }
  };

  const onSuccess = () => {
    dispatch(subscriptionAction.clearData({}));
    getPropertyList();
  };

  // Resume Subscription Actions
  const handleResumeSubscription = () => {
    dispatch(
      subscriptionAction.getResumeSubscriptionData({
        url: `customer/resume-subscription/${selectedRow?.subscription?.id}`,
        token: true,
      })
    );
    setIsResumeSubscription(false);
  };

  const closeResumeSubscriptionModal = () => {
    getPropertyList();
    dispatch(subscriptionAction.fetchResumeSubscriptionData({}));
  };

  useEffect(() => {
    if (getObjLength(subscriptionRenewed)) {
      if (subscriptionRenewed?.success) {
        if (subscriptionRenewed?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !subscriptionRenewed?.data?.actionRequired &&
          subscriptionRenewed?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, subscriptionRenewed]);

  useEffect(() => {
    if (getObjLength(retryPaymentData)) {
      if (retryPaymentData?.success) {
        if (retryPaymentData?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !retryPaymentData?.data?.actionRequired &&
          retryPaymentData?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, retryPaymentData]);

  useEffect(() => {
    if (getObjLength(totalPaymentDetails)) {
      if (totalPaymentDetails?.success) {
        setOpenRenewPlanModal(true);
      } else {
        setOpenRenewPlanModal(false);
        setClickedRenewBtn(false);
      }
    }
  }, [totalPaymentDetails]);

  const count_endData =
    new Date(propertiesData[activeStep]?.subscription?.end_at) > new Date();

  return (
    <>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          className="pb-3 pb-lg-4"
        >
          <Paper className="gridpaper" elevation={0}>
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box className="property-image">
                  {!propertiesData[activeStep]?.image && isMapDisplay ? (
                    <NewMap
                      className="map-style"
                      zoom={15}
                      center={{
                        lat: parseFloat(propertiesData[activeStep]?.latitude),
                        lng: parseFloat(propertiesData[activeStep]?.longitude),
                      }}
                      coordinates={[]}
                      streetView={true}
                    />
                  ) : (
                    <Photo
                      sourceUrl={propertiesData[activeStep]?.image}
                      className="w-100 rounded-start rounded-end"
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box className="property-details position-relative">
                  {propertiesData[activeStep]?.is_verified === 0 && (
                    <div className="d-flex justify-content-end">
                      <Tooltip
                        title="Please contact the admin to verify this property as you skipped the security questions while adding it."
                        arrow
                        placement="top"
                      >
                        <Chip
                          variant="outlined"
                          label="Awaiting Property Verification"
                          color="error"
                        />
                      </Tooltip>
                    </div>
                  )}
                  <Typography
                    variant="body1"
                    className="securedTitle text-uppercase mb-2"
                  >
                    {propertiesData[activeStep]?.terminated === 1 ? (
                      <p>
                        <span className="text-danger "> terminated</span>
                      </p>
                    ) : propertiesData[activeStep]?.status === "Verified" ? (
                      <p>
                        <span className="text-success "> Verified</span>
                      </p>
                    ) : propertiesData[activeStep]?.status === "Active" ? (
                      <p>
                        <span className="text-success "> Secured</span>
                      </p>
                    ) : (
                      <p>
                        <span className="text-danger "> Pending</span>
                      </p>
                    )}
                  </Typography>
                  <Typography variant="h5" className="propertyName mb-2">
                    {propertiesData[activeStep]?.address},{" "}
                    {propertiesData[activeStep]?.state},{" "}
                    {propertiesData[activeStep]?.zip}
                  </Typography>
                  {(propertiesData[activeStep]?.subscription?.is_active &&
                    !propertiesData[activeStep]?.subscription?.expired) ||
                  propertiesData[activeStep]?.subscription?.cancel_at !==
                    null ? (
                    <Typography variant="body2" className="subscription my-2">
                      <b>Subscription:</b>
                      {` ${
                        propertiesData[activeStep]?.subscription?.start_at
                          ? propertiesData[activeStep]?.subscription?.start_at
                          : ""
                      }`}
                      -
                      {` ${
                        propertiesData[activeStep]?.subscription?.end_at
                          ? propertiesData[activeStep]?.subscription?.end_at
                          : ""
                      }`}
                    </Typography>
                  ) : (
                    <Typography variant="body2" className="subscription my-2">
                      <b>Subscription:</b>&nbsp;-
                    </Typography>
                  )}
                  {propertiesData[activeStep]?.subscription &&
                  propertiesData[activeStep]?.subscription !== null ? (
                    <Typography variant="body2" className="subscription my-2">
                      {propertiesData[activeStep]?.subscription?.cancel_at ===
                      null ? (
                        propertiesData[activeStep]?.subscription?.is_active &&
                        propertiesData[activeStep]?.subscription?.expired ===
                          false &&
                        propertiesData[activeStep]?.subscription?.cancel_at ===
                          null ? (
                          <>
                            <b>Subscription Status: </b>
                            <span className="text-success content-text">
                              Active
                            </span>
                          </>
                        ) : (
                          <>
                            <b>Subscription Status: </b>
                            <span className="text-danger content-text">
                              Expired
                            </span>
                          </>
                        )
                      ) : !propertiesData[activeStep]?.terminated ? (
                        <>
                          <b>Subscription Status: </b>
                          <span className="text-warning content-text">
                            Cancelled
                          </span>
                        </>
                      ) : null}
                      {/* ) : propertiesData[activeStep]?.subscription?.expired ===
                          false &&
                        propertiesData[activeStep]?.subscription
                          ?.payment_status === "failed" ? (
                        <>
                          <b>Subscription Status : </b>
                          <span className="text-danger content-text">
                             Failed 
                          </span>
                        </>
                      ) : (
                        "" 
                      }
                        */}
                    </Typography>
                  ) : (
                    <Typography variant="body2" className="subscription my-2">
                      <b>Subscription Status : </b>
                      <span className="text-info content-text">
                        Not Applicable
                      </span>
                    </Typography>
                  )}

                  {propertiesData[activeStep]?.subscription?.cancel_at && (
                    <>
                      <Typography variant="body2" className="subscription my-2">
                        <b>Cancelled at</b>:{" "}
                        {propertiesData[activeStep]?.subscription?.cancel_at}
                      </Typography>
                    </>
                  )}
                  <Stack className="mt-3" direction="row" spacing={1} mb={2}>
                    <Tooltip
                      title="View Property Details"
                      arrow
                      placement="top"
                    >
                      <Button
                        variant="link"
                        className="viewBtn text-uppercase"
                        onClick={() => {
                          dispatch(
                            propertiesAction.setViewDetailsPropertyData(
                              propertiesData[activeStep]
                            )
                          );

                          handleOpenViewPropertyDetailModal();
                        }}
                      >
                        View Details
                      </Button>
                    </Tooltip>
                    {!isSecondaryUser &&
                    propertiesData[activeStep]?.is_owner &&
                    propertiesData[activeStep]?.terminated === 0 &&
                    propertiesData[activeStep]?.subscription?.is_active &&
                    propertiesData[activeStep]?.subscription?.id
                      ? propertiesData[activeStep]?.subscription?.cancel_at ===
                          null &&
                        !propertiesData[activeStep]?.subscription?.expired && (
                          //   ?.payment_status !== "failed" && (
                          <Tooltip
                            title="Cancel Subscription"
                            arrow
                            placement="top"
                          >
                            <Button
                              variant="link"
                              className="viewBtn text-uppercase text-danger"
                              onClick={() => {
                                handleOpenCancelSubscriptionModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </Tooltip>
                        )
                      : null}
                    {!isSecondaryUser &&
                    propertiesData[activeStep]?.is_owner &&
                    !propertiesData[activeStep]?.added_by_other_user &&
                    (propertiesData[activeStep]?.subscription === null ||
                      (propertiesData[activeStep]?.terminated === 1 &&
                        !propertiesData[activeStep]?.show_retry_button) ||
                      (propertiesData[activeStep]?.subscription?.id &&
                        propertiesData[activeStep]?.terminated === 0 &&
                        propertiesData[activeStep]?.subscription?.cancel_at !==
                          null &&
                        count_endData)) ? (
                      //  new Date(
                      //  propertiesData[activeStep]?.subscription?.end_at
                      //) > new Date()
                      count_endData &&
                      propertiesData[activeStep]?.terminated === 0 &&
                      !propertiesData[activeStep]?.subscription?.refunded ? (
                        <>
                          <Tooltip
                            title="Resume Subscription"
                            arrow
                            placement="top"
                          >
                            <Button
                              variant="link"
                              className="viewBtn text-uppercase text-danger"
                              onClick={() => {
                                setSelectedRow(propertiesData[activeStep]);
                                setIsResumeSubscription(true);
                              }}
                            >
                              Resume Subscription
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            title="Add Subscription"
                            arrow
                            placement="top"
                          >
                            <Button
                              variant="link"
                              className="viewBtn text-uppercase text-danger"
                              onClick={() => {
                                setSelectedProperty(propertiesData[activeStep]);
                                handleOpenAddSubscriptionModal();
                              }}
                            >
                              Add Subscription
                            </Button>
                          </Tooltip>
                        </>
                      )
                    ) : null}

                    {/* {!isSecondaryUser &&
                      propertiesData[activeStep]?.terminated === 0 &&
                      (propertiesData[activeStep]?.subscription === null ||
                        (propertiesData[activeStep]?.subscription?.id &&
                          propertiesData[activeStep]?.subscription
                            ?.cancel_at !== null)) &&
                      // || propertiesData[activeStep]?.subscription
                      // ?.payment_status === "failed"
                      !(
                        getDateInYYYYMMDDFormat(
                          new Date(
                            propertiesData[activeStep]?.subscription?.end_at
                          )
                        ) ===
                        propertiesData[activeStep]?.subscription?.cancel_at
                      ) && (
                        <Tooltip title="Add Subscription" arrow placement="top">
                          <Button
                            variant="link"
                            className="viewBtn text-uppercase text-danger"
                            onClick={() => {
                              setSelectedProperty(propertiesData[activeStep]);
                              handleOpenAddSubscriptionModal();
                            }}
                          >
                            Add Subscription
                          </Button>
                        </Tooltip>
                      )} */}

                    {!isSecondaryUser &&
                    propertiesData[activeStep]?.is_owner &&
                    !propertiesData[activeStep]?.added_by_other_user &&
                    propertiesData[activeStep]?.subscription !== null &&
                    propertiesData[activeStep]?.terminated === 0 &&
                    propertiesData[activeStep]?.subscription?.id &&
                    propertiesData[activeStep]?.subscription?.cancel_at ===
                      null &&
                    new Date(
                      propertiesData[activeStep]?.subscription?.end_at
                    ) <= new Date() ? (
                      <Tooltip title="Renew Subscription" arrow placement="top">
                        <Button
                          variant="link"
                          className="renew_Btn success_popup_btn"
                          onClick={() => {
                            if (
                              propertiesData[activeStep]?.subscription
                                ?.expired === false &&
                              propertiesData[activeStep]?.subscription
                                ?.payment_status === "failed"
                            ) {
                              setRetryPayment(true);
                            } else {
                              setRetryPayment(false);
                            }
                            setClickedRenewBtn(true);
                            setSelectedRow(propertiesData[activeStep]);
                            handleGetEstimatedAmount(
                              propertiesData[activeStep]
                            );
                          }}
                        >
                          Renew
                        </Button>
                      </Tooltip>
                    ) : null}

                    {/* {!isSecondaryUser &&
                      (propertiesData[activeStep]?.subscription !== null ||
                        propertiesData[activeStep]?.subscription) &&
                      !propertiesData[activeStep]?.subscription?.is_active &&
                      propertiesData[activeStep]?.subscription?.expired && (
                        // || propertiesData[activeStep]?.subscription
                        // ?.payment_status === "failed"
                        <Tooltip title="Add Subscription" arrow placement="top">
                          <Button
                            variant="link"
                            className="viewBtn text-uppercase text-danger"
                            onClick={() => {
                              if (
                                propertiesData[activeStep]?.subscription
                                  ?.expired === false &&
                                propertiesData[activeStep]?.subscription
                                  ?.payment_status === "failed"
                              ) {
                                setRetryPayment(true);
                              } else {
                                setRetryPayment(false);
                              }
                              setSelectedRow(propertiesData[activeStep]);
                              handleGetEstimatedAmount(
                                propertiesData[activeStep]
                              );
                            }}
                          >
                            Renew Subscription
                          </Button>
                        </Tooltip>
                      )} */}

                    {!isSecondaryUser &&
                    !propertiesData[activeStep]?.added_by_other_user &&
                    !propertiesData[activeStep]?.subscription &&
                    propertiesData[activeStep]?.show_retry_button ? (
                      <Tooltip title="Add Subscription" arrow placement="top">
                        <Button
                          variant="link"
                          className="viewBtn text-uppercase text-danger"
                          onClick={() => {
                            setSelectedProperty(propertiesData[activeStep]);
                            handleOpenAddSubscriptionModal();
                          }}
                        >
                          Retry Subscription
                        </Button>
                      </Tooltip>
                    ) : null}
                  </Stack>
                  {!isSecondaryUser && (
                    <>
                      <Divider className="d-block d-lg-none" />
                      <AddPropertyPopup
                        classes={"addBtn w-100 d-block d-lg-none mt-3"}
                        getPropertyList={callPaginationAPI}
                        isUserCreateProperty={true}
                        setIsMapDisplay={setIsMapDisplay}
                        isMapDisplay={isMapDisplay}
                      />
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          className="pb-3 pb-lg-3 border-left d-flex align-items-center justify-content-center pt-0"
        >
          <Paper
            elevation={0}
            className="w-100 propertyValue gridpaper text-center position-relative"
          >
            <Typography variant="caption" className="valutTitle">
              Value index
            </Typography>
            {!isNaN(propertiesData[activeStep]?.approx_price) ||
            propertiesData[activeStep]?.approx_price !== "N/A" ? (
              <Typography variant="h3" className="priceValue my-4">
                {`${currencyFormatter(
                  propertiesData[activeStep]?.approx_price || 0.0
                )}`}
              </Typography>
            ) : (
              <Typography variant="h5" className="priceValueDisclosure my-4">
                Non-disclosure Property
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Renew plan modal */}
      {openRenewPlanModal && clickedRenewBtn && (
        <RenewPlanModal
          open={isRenewPlanModalOpen}
          loader={subscriptionLoader}
          handleClose={handleRenewPlanModalClose}
          onSubmit={handleRenewPlan}
          isRenewFinalAmtModalOpen={isRenewFinalAmtModalOpen}
          setIsRenewFinalAmtModalOpen={setIsRenewFinalAmtModalOpen}
          // for 3d secure card paymnet
          paymnetData={retryPayment ? retryPaymentData : subscriptionRenewed}
          user_id={selectedRow?.subscription?.user_id}
          onSuccess={onSuccess}
          setIsRenewPlanModalOpen={setIsRenewPlanModalOpen}
          selectedRow={selectedRow}
          // for 3d secure card paymnet events
          pixelEventMessage={
            retryPayment ? "Retry Payment" : "Subscription Renewed"
          }
          apiCall={getPropertyList}
        />
      )}

      {getObjLength(subscriptionRenewed) && valdiateModal ? (
        subscriptionRenewed?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              onSuccess();
              handleRenewPlanModalClose();
            }}
            mesage={subscriptionRenewed?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(subscriptionAction.clearData({}));
            }}
            mesage={subscriptionRenewed?.data?.message}
          />
        )
      ) : null}

      {/* for retry payment */}
      {getObjLength(retryPaymentData) && valdiateModal ? (
        retryPaymentData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              onSuccess();
              handleRenewPlanModalClose();
            }}
            mesage={retryPaymentData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(subscriptionAction.clearData({}));
            }}
            mesage={retryPaymentData?.data?.message}
          />
        )
      ) : null}

      {/* Resume subscription request */}
      {isResumeSubscription ? (
        <>
          <AlertDialog
            type="Warning"
            openPopup={isResumeSubscription}
            onClose={true}
            onCloseFun={() => {
              setIsResumeSubscription(false);
            }}
            mesage={
              "Are you sure you want to Resume the subscription for this proeprty?"
            }
            // loader={cancelChangeSubscriptionRequestLoader}
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn mx-2"
                  onClick={() => setIsResumeSubscription(false)}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => handleResumeSubscription()}
                >
                  Yes
                </Button>
              </>
            }
            footer={false}
          />
        </>
      ) : null}

      {getObjLength(resumeSubscriptionData) ? (
        resumeSubscriptionData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              closeResumeSubscriptionModal();
            }}
            mesage={resumeSubscriptionData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(subscriptionAction.clearData({}));
            }}
            mesage={resumeSubscriptionData?.data?.message}
          />
        )
      ) : null}

      <Loader open={totalPaymentLoader || subscriptionLoader} />
    </>
  );
};

export default MapSection;
