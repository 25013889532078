import React, { lazy } from "react";

import Spouse from "../components/spouse";
import Login from "../components/login/index";
import LeadPage from "../components/lead-page";
import Admins from "../components/admin/Admins";
import LogsTable from "../components/customer-logs";
import Homepage from "../components/lennar/index.js";
import Providers from "../components/admin/providers";
import { ROLES } from "../components/helpers/constants";
import UserSignup from "../components/user-signup/index";
import AddLeadsIndex from "../components/admin/AddLeads";
import Documents from "../components/userDocuments/index";
import AdminLogsList from "../components/admin/log-lists";
import AgentSignup from "../components/agent-signup/index";
import Promocode from "../components/admin/promocodes/index";
import Properties from "../components/userProfile/properties";
import Subscriptions from "../components/Subscriptions/index";
import ForCustomer from "../components/desktop/ForCustomer.js";
import UserSettingIndex from "../components/userProfile/Settings";
import Professional from "../components/admin/professionals/index";
import PropertyUsersIndex from "../components/admin/PropertyUsers";
import ServicersLogin from "../components/Service-Provider/signIn";
import CountyAdmin from "../components/admin/CountyAdmin/index.js";
import ServiceProviders from "../components/admin/serviceProviders";
import Professionals from "../components/userProfile/Professionals";
import CountyRecorder from "../components/county-recorder/index.js";
import DashBoard from "../components/admin/AdminDashboard/DashBoard";
import ExportCSVDetails from "../components/admin/exportCSV/index.js";
import DocumentRequest from "../components/propertyRequestedDocuemnts";
import UserProfile from "../components/userProfile/Dashboard/dashboard";
import SendInvitation from "../components/professional/send-invitation";
import PaymentDone from "../components/lennar/subscription/PaymentDone";
import ServiceProviderSignup from "../components/Service-Provider/signup";
import RequestDocumentList from "../components/admin/requestDocumentList";
import PropertyRiskManagement from "../components/PropertyRiskManagement";
import AdminProfile from "../components/admin/AdminDashboard/Admin-Profile";
import ServicerProfile from "../components/Service-Provider/servicerProfile";
import CreateNewPass from "../components/create-reset-password/createNewPass";
import ResetPassword from "../components/create-reset-password/resetPassword";
import AdminFreeMonitoring from "../components/admin/FreeMonitoring/index.js";
import VerifyOTP from "../components/create-reset-password/verifyResetPassOTP";
import LennarCreateAccount from "../components/lennar/create-account/index.js";
import ProfessionalsProfile from "../components/professional/Professionals-Profile";
import LeadGenDesktop from "../components/PropertyRiskManagement/LeadGenDesktop.js";
import ServicerReqDocumnets from "../components/Service-Provider/RequestedDocumnets";
import AddUserDetailsFromMail from "../components/desktop/AddUserDetailsFromMail.js";
import LennarPropertyList from "../components/lennar/subscription/LennarPropertyList";
import AddUserPageUpdatedFigma from "../components/admin/PropertyUsers/AddNewUserPage";
import CancellationPolicy from "../components/lennar/subscription/CancellationPolicy ";
import ProfessionalProperties from "../components/professional/professional-properties";
import { PropertyRiskReport } from "../components/county-recorder/PropertyRiskReport.js";
import ProfessionalUsersList from "../components/professional/users/ProfessionalUsersList";
import StatewiseRecordingFees from "../components/admin/statewise-recording-fees/index.js";
import RequestDocumentForm from "../components/for-service-provider/RequestDocumentForm.js";
import GenericLandingPage from "../components/PropertyRiskManagement/GenericLandingPage.js";
import LeadGenShortVersion from "../components/PropertyRiskManagement/LeadGenShortVersion.js";
import ProfessionalDashboard from "../components/professional/dashboard/ProfessionalDashboard";
import ServicerCreateNewPass from "../components/Service-Provider/create-reset-password/createNewPass";
import ServicerResetPassword from "../components/Service-Provider/create-reset-password/resetPassword";
import NationalHostsLandingPage from "../components/PropertyRiskManagement/NationalHostsLandingPage.js";
const CreativeLandingPage = lazy(() =>
  import("../components/desktop/CreativeLandingPage.js")
);

const SUBDOMAIN = process.env.REACT_APP_SUBDOMAIN;
// lazy import of routes
// const NationalHostsLandingPage = lazy(() => import("../components/PropertyRiskManagement/NationalHostsLandingPage"));
const LandingPage = lazy(() => import("../components/desktop"));
const HowItWorks = lazy(() => import("../components/howItsWorks"));
const Pricing = lazy(() => import("../components/pricing"));
const PrivacyPolicyPage = lazy(() => import("../components/privacy-policy"));
const ForServiceProvider = lazy(() =>
  import("../components/for-service-provider")
);
const TermsAndConditions = lazy(() => import("../components/terms-conditions"));
const SiteMapPage = lazy(() => import("../components/desktop/SiteMap"));
const is_scrp_hp = localStorage.getItem("scrp_hp");
export const publicRoutes = [
  {
    path: "/",
    component: <CreativeLandingPage />,
    title: "Home",
    isLandingPage: true,
  },
  {
    path: "/equity-protect",
    component: <CreativeLandingPage />,
    title: "Home",
    isLandingPage: true,
    hideFromSitemap: true,
  },
  {
    path: "/how-it-works",
    component: <HowItWorks />,
    title: "How it Works ",
  },
  {
    path: "/pricing",
    component: <Pricing />,
    title: "Pricing ",
  },
  {
    path: ":county_name/pricing",
    component: <Pricing />,
    title: "Pricing ",
    hideFromSitemap: true,
  },
  {
    path: "/property-risk-management",
    component: <LeadGenDesktop />,
    title: "Property Risk Management",
    hideNavbar: true,
  },
  {
    path: "/generic-host",
    component: <GenericLandingPage />,
    title: "Generic Host",
    hideNavbar: true,
  },
  {
    path: "/Equity-Guard",
    component: <GenericLandingPage />,
    title: "Equity Guard",
    hideNavbar: true,
  },
  {
    path: "/gallagher",
    component: <NationalHostsLandingPage radio={"Mike Gallagher"} />,
    title: "Property Risk Report",
    hideNavbar: true,
    hideFromSitemap: true,
  },
  {
    path: "/prager",
    component: <NationalHostsLandingPage radio={"Dennis Prager"} />,
    title: "Property Risk Report",
    hideNavbar: true,
    hideFromSitemap: true,
  },
  {
    path: "/gorka",
    component: <NationalHostsLandingPage radio={"Dr. Sebastian Gorka"} />,
    title: "Property Risk Report",
    hideNavbar: true,
    hideFromSitemap: true,
  },
  {
    path: "/county-recorder/:county_name",
    component: <CountyRecorder />,
    title: "County Recorder ",
    hideFromSitemap: true,
  },
  {
    path: "/lead-gen",
    component: <LeadGenShortVersion />,
    title: "Lead Generator",
    hideFromSitemap: true,
    hideNavbar: true,
  },
  {
    path: "/property-risk-report",
    component: <PropertyRiskReport />,
    title: "Property Risk Report",
    hideFromSitemap: true,
  },
  {
    path: "/for-service-provider",
    component: <ForServiceProvider />,
    title: "Go To  Service Provider",
    hideFromSitemap: is_scrp_hp ? true : false,
  },
  {
    path: "/privacy",
    component: <PrivacyPolicyPage />,
    title: "Privacy Policy",
  },
  {
    path: "/terms",
    component: <TermsAndConditions />,
    title: "Terms & Conditions",
    hideFromSitemap: true,
  },
  {
    path: "/sitemap",
    component: <SiteMapPage />,
    title: "Sitemap",
    hideFromSitemap: true,
  },
];

export const userAdminRoutes = [
  // {
  //   path: "/security-Info",
  //   component: <Demo />,
  //   title: "Demo ",
  // },
  {
    path: "/create-account",
    component: <UserSignup />,
    title: "Create Account ",
    // hideFromSitemap: true,
  },
  {
    path: ":county_name/create-account",
    component: <UserSignup />,
    title: "Create Account ",
    hideFromSitemap: true,
  },
  {
    path: "/create-account/*",
    component: <UserSignup />,
    title: "Create Account ",
    hideFromSitemap: true,
  },
  // {
  //   path: "/create-agent-account",
  //   component: <AgentSignup />,
  //   title: "Create Agent Account ",
  // },
  {
    path: "/login",
    component: <Login />,
    title: "Login ",
    hideLoginURL: true,
  },
  {
    path: "/create-new-password",
    component: <CreateNewPass />,
    title: "Create New Password ",
    hideFromSitemap: true,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
    title: "Forgot Password ",
    // hideFromSitemap: true,
  },
  // {
  //   path: "/verify-otp-password",
  //   component: <VerifyOTP />,
  //   title: "Reset Password ",
  // },
  {
    path: "/add-user-details/:userToken",
    component: <AddUserDetailsFromMail />,
    title: "Add User Details",
    hideFromSitemap: true,
    footer: true,
    hideLoginURL: true,
  },
];

export const loggedInUserRoutes = [
  {
    path: "/user-profile",
    component: <UserProfile />,
    title: "User Profile",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  {
    path: "/subscriptions",
    component: <Subscriptions />,
    title: "Subscriptions",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  {
    path: "/properties",
    component: <Properties roles={[ROLES?.customer, ROLES?.secondaryUser]} />,
    title: "Properties",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  // {
  //   path: "/market-value",
  //   component: <MarketValue />,
  //   title: "Properties",
  //   role: [ROLES.customer, ROLES.secondaryUser],
  // },
  {
    path: "/documents",
    component: <Documents />,
    title: "Documents",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  {
    path: "/sub-user",
    component: <Spouse />,
    title: "Allow Access",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  {
    path: "/professionals",
    component: <Professionals />,
    title: "Professionals",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  {
    path: "/settings",
    component: <UserSettingIndex />,
    title: "User Settings",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  {
    path: "/logs",
    component: <LogsTable />,
    title: "Logs",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
  {
    path: "/requested-documents",
    component: <DocumentRequest />,
    title: "Requested Documents",
    role: [ROLES.customer, ROLES.secondaryUser],
  },
];

export const adminRoutes = [
  {
    path: "/admin-dashboard",
    component: <PropertyUsersIndex />,
    title: "Admin Dashboard",
    role: [ROLES.admin, ROLES.subAdmin, ROLES.provider, ROLES.countyRecorder],
  },
  {
    path: "/admin-dashboard/:propertyUserID",
    component: <PropertyUsersIndex />,
    title: "Admin Dashboard",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin, ROLES.provider, ROLES.countyRecorder],
  },
  {
    path: "/admin-properties",
    component: <DashBoard />,
    title: "Admin Properties",
    role: [ROLES.admin, ROLES.subAdmin, ROLES.provider, ROLES.countyRecorder],
  },
  {
    path: "/admin-properties/:propertyId",
    component: <DashBoard />,
    title: "Admin Dashboard",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin, ROLES.provider, ROLES.countyRecorder],
  },
  {
    path: "/admin-professionals",
    component: <Professional />,
    title: "Professionals",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-professionals/:professionalId",
    component: <Professional />,
    title: "Professionals",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-profile",
    component: <AdminProfile />,
    title: "Admin Profile",
    role: [ROLES.admin, ROLES.subAdmin, ROLES?.provider, ROLES.countyRecorder],
  },
  {
    path: "/add-leads",
    component: <AddLeadsIndex />,
    title: "Add Leads",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/promocodes",
    component: <Promocode />,
    title: "Promo Code",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/service-providers",
    component: <ServiceProviders />,
    title: "Service Providers",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/service-providers/*",
    component: <ServiceProviders />,
    title: "Service Providers",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-providers",
    component: <Providers />,
    title: "Providers",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-providers/:providerId",
    component: <Providers />,
    title: "Providers",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-county",
    component: <CountyAdmin />,
    title: "Providers",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-county/:countyId",
    component: <CountyAdmin />,
    title: "Providers",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admins",
    component: <Admins />,
    title: "Admins",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admins/:adminID",
    component: <Admins />,
    title: "Admins",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/request-document-list",
    component: <RequestDocumentList />,
    title: "Requested Doucmnets List",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-property-monitoring",
    component: <AdminFreeMonitoring />,
    title: "Property Monitoring",
    role: [ROLES.admin, ROLES.subAdmin, ROLES.countyRecorder],
  },
  {
    path: "/admin-recording-fees",
    component: <StatewiseRecordingFees />,
    title: "Recording Fees",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-export-csv",
    component: <ExportCSVDetails />,
    title: "Export Payments",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-logs",
    component: <AdminLogsList />,
    title: "Logs",
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/admin-logs/:user_id",
    component: <AdminLogsList />,
    title: "Logs",
    hideFromSitemap: true,
    role: [ROLES.admin, ROLES.subAdmin],
  },
  {
    path: "/add-new-users",
    component: <AddUserPageUpdatedFigma />,
    title: "Add a new Users",
    role: [ROLES.admin, ROLES.subAdmin],
  },
];

export const professionalRoutes = [
  {
    path: "/professional-dashboard",
    component: <ProfessionalDashboard />,
    title: "Professional Dashboard",
    role: [ROLES.professional],
  },
  {
    path: "/professional-profile",
    component: <ProfessionalsProfile />,
    title: "Professional Profile",
    role: [ROLES.professional],
  },
  {
    path: "/professional-users-list",
    component: <ProfessionalUsersList />,
    title: "Users",
    role: [ROLES.professional],
  },
  {
    path: "/send-invitation",
    component: <SendInvitation />,
    title: "Send Invitation",
    role: [ROLES.professional],
  },
  {
    path: "/professional-properties",
    component: <ProfessionalProperties />,
    title: "Professional Properties",
    role: [ROLES.professional],
  },
  {
    path: "/professional-subscriptions",
    component: <Subscriptions />,
    title: "Professional Subscription",
    role: [ROLES.professional],
  },
  {
    path: "/professional-document",
    component: <Documents />,
    title: "Professional Document",
    role: [ROLES.professional],
  },
  {
    path: "/professional-requested-documents",
    component: <DocumentRequest />,
    title: "Document Request",
    role: ROLES.professional,
  },
  {
    path: "/professional-logs",
    component: <LogsTable />,
    title: "Professional Logs",
    role: [ROLES.professional],
  },
  {
    path: "/professional-allow-access",
    component: <Spouse />,
    title: "Professional Allow Access",
    role: [ROLES.professional],
  },
  {
    path: "/professional-settings",
    component: <UserSettingIndex />,
    title: "Professional Settings",
    role: [ROLES.professional],
  },
];

export const serviceProviderRoutes = [
  // {
  //   path: "/",
  //   component: <ServiceProvider />,
  //   title: "Service Provider",
  //   role: "serviceProvider",
  //   domain: SUBDOMAIN,
  // },
  {
    path: "/create-account",
    component: <ServiceProviderSignup />,
    title: "Create Account",
    // role: "serviceProvider",
    // hideFromSitemap: true,
    domain: SUBDOMAIN,
  },
  {
    path: "/login",
    component: <ServicersLogin />,
    title: "Login",
    // role: "serviceProvider",
    // hideFromSitemap: true,
    domain: SUBDOMAIN,
  },
  {
    path: "/create-new-password",
    component: <ServicerCreateNewPass />,
    title: "Create New Password ",
    hideFromSitemap: true,
    domain: SUBDOMAIN,
  },
  {
    path: "/reset-password",
    component: <ServicerResetPassword />,
    title: "Forgot Password ",
    // hideFromSitemap: true,
    domain: SUBDOMAIN,
  },
  // {
  //   path: "/verify-otp-password",
  //   component: <ServicerVerifyOTP />,
  //   title: "Reset Password ",
  //   domain: SUBDOMAIN,
  // },
];

export const serviceProviderPublicRoutes = [
  {
    path: "/",
    component: <CreativeLandingPage />,
    title: "Home",
    // role: ROLES.serviceProvider,
    isLandingPage: true,
    domain: SUBDOMAIN,
  },
  {
    path: "/how-it-works",
    component: <HowItWorks />,
    title: "How it Works ",
    hideFromSitemap: true,
    // role: ROLES.serviceProvider,
    domain: SUBDOMAIN,
  },
  {
    path: "/pricing",
    component: <Pricing />,
    title: "Pricing ",
    hideFromSitemap: true,
    // role: ROLES.serviceProvider,
    domain: SUBDOMAIN,
  },
  {
    path: "/for-customer",
    component: <ForCustomer />,
    title: "Go To Customer",
    // role: ROLES.serviceProvider,
    domain: SUBDOMAIN,
    onClickEvent: true,
  },
  {
    path: "/property-risk-management",
    component: <PropertyRiskManagement />,
    title: "Property Risk Management ",
    domain: SUBDOMAIN,
  },
  {
    path: "/privacy",
    component: <PrivacyPolicyPage />,
    title: "Privacy Policy",
    role: ROLES.serviceProvider,
    hideFromSitemap: true,
    domain: SUBDOMAIN,
  },
  // {
  //   path: "/terms",
  //   component: <TermsAndConditions />,
  //   title: "Terms & Conditions",
  //   role: ROLES.serviceProvider,
  //   hideFromSitemap: true,
  //   domain: SUBDOMAIN,
  // },
  {
    path: "/sitemap",
    component: <SiteMapPage />,
    title: "Sitemap",
    hideFromSitemap: true,
    domain: SUBDOMAIN,
  },
];

export const forServiceProvicerRoute = [
  {
    path: "/for-service-provider",
    component: <ForServiceProvider />,
    title: "Request Customer's Property Documnet",
    // role: ROLES.serviceProvider,
    domain: SUBDOMAIN,
  },
];

export const serviceProviderLoginRoutes = [
  {
    path: "/servicer-profile",
    component: <ServicerProfile />,
    title: "Profile",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
  // {
  //   path: "/users",
  //   component: <ServicerUsers />,
  //   title: "Servicer Users",
  //   domain: SUBDOMAIN,
  //   role: ROLES.serviceProvider,
  // },
  {
    path: "/documents",
    component: <ServicerReqDocumnets />,
    title: "Customer's Document",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
  {
    path: "/servicer-properties",
    component: <ProfessionalProperties />,
    title: "Properties",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
  {
    path: "/request-document",
    component: <RequestDocumentForm />,
    title: "Properties",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
  {
    path: "/servicer-subscriptions",
    component: <Subscriptions />,
    title: "Subscriptions",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
  {
    path: "/servicer-documents",
    component: <Documents />,
    title: "Documents",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },

  {
    path: "/servicer-logs",
    component: <LogsTable />,
    title: "Logs",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
  {
    path: "/servicer-allow-access",
    component: <Spouse />,
    title: "Allow Access",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
  {
    path: "/servicer-requested-documents",
    component: <DocumentRequest />,
    title: "Requested Documents",
    domain: SUBDOMAIN,
    role: ROLES.serviceProvider,
  },
];

export const tryDomainRoutes = [
  {
    path: "/",
    component: <LeadPage />,
    title: "Lead Page",
  },
];

export const lannerPublicRoutes = [
  {
    path: "/",
    component: (
      <>
        <Homepage />
      </>
    ),
    title: "Lenner Accoount",
  },
  {
    path: ":provider_type",
    component: (
      <>
        <Homepage />
      </>
    ),
    title: "Lenner Accoount",
  },
  {
    path: ":provider_type/create-account",
    component: (
      <>
        <LennarCreateAccount />
      </>
    ),
    title: "Lenner Accoount",
  },
  {
    path: "/create-account",
    component: (
      <>
        <LennarCreateAccount />
      </>
    ),
    title: "Lenner Accoount",
  },
  {
    path: ":provider_type/do-payment",
    component: (
      <>
        <>
          {/* <Elements stripe={stripePromise}> */}
          <LennarPropertyList />
          {/* </Elements> */}
        </>
      </>
    ),
    title: "Lennar Subscription",
    role: [ROLES.lennar],
  },
  {
    path: "/do-payment",
    component: (
      <>
        <>
          {/* <Elements stripe={stripePromise}> */}
          <LennarPropertyList />
          {/* </Elements> */}
        </>
      </>
    ),
    title: "Lennar Subscription",
    role: [ROLES.lennar],
  },
  {
    path: ":provider_type/cancellation-policy",
    component: (
      <>
        <CancellationPolicy />
      </>
    ),
    title: "Cancellation Policy",
    role: [ROLES.lennar],
  },
  {
    path: "/cancellation-policy",
    component: (
      <>
        <CancellationPolicy />
      </>
    ),
    title: "Cancellation Policy",
    role: [ROLES.lennar],
  },
  {
    path: ":provider_type/payment-done",
    component: (
      <>
        <PaymentDone />
      </>
    ),
    title: "Payment Done",
    role: [ROLES.lennar],
  },
  {
    path: "/payment-done",
    component: (
      <>
        <PaymentDone />
      </>
    ),
    title: "Payment Done",
    role: [ROLES.lennar],
  },
];

export const lannerRestrictedRoutes = [
  // {
  //   path: "/payment-done",
  //   component: (
  //     <>
  //       <PaymentDone />
  //     </>
  //   ),
  //   title: "Payment Done",
  //   role: [ROLES.lennar],
  // },
];

export const ROUTESFORSITEMAP = [
  ...publicRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...userAdminRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...loggedInUserRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...adminRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...professionalRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  { sevicepovidrRoute: "start" },

  ...serviceProviderRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...serviceProviderPublicRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...forServiceProvicerRoute?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...serviceProviderLoginRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  { tryRoute: "start" },
  ...tryDomainRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  { lennar: "start" },
  ...lannerPublicRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),

  ...lannerRestrictedRoutes?.map((route) => ({
    url: route?.path,
    lastmod: new Date().toISOString(),
  })),
];
