import React, { useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, Typography } from "@mui/material";

import NewMap from "../../common/NewMap";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import RiskoMeterChart from "../../common/charts/RiskoMeterChart";
import { currencyFormatter, getObjLength } from "../../helpers/utils";
import PropertyRiskManagementForm from "../../PropertyRiskManagement/PropertyRiskManagementForm";
import { propertyRiskManagementAction } from "../../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

import "../../county-recorder/PropertyRiskReport.scss";

const AdminProeprtyRiskReport = ({
  isRiskReport = false,
  setIsRiskReport = () => {},
  closeSubscribeModal = () => {},
}) => {
  const [subscribeModal, setSubscribeModal] = useState(false);

  const dispatch = useDispatch();

  // store data
  const { propertyData, riskManagementLoader, subscribeFreeMonitoring } =
    useSelector((state) => state?.propertyRiskManagement);

  const subscribeFreeMonitor = () => {
    setSubscribeModal(false);

    dispatch(
      propertyRiskManagementAction.subscribeFreeMonitoring({
        url: `county-subscription/${propertyData?.data?.id}/1`,
        data: {},
      })
    );
  };

  let proeprtyStatus = propertyData?.data?.status;

  return (
    <>
      {!isRiskReport ? (
        <Box className="d-flex p-5">
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Box
                className="property_form property-risk-page-content lead_form"
                id="lead-page-form"
              >
                <PropertyRiskManagementForm
                  getFreeReport={true}
                  source={"Admin"}
                  isAdmin={true}
                  setIsRiskReport={setIsRiskReport}
                />
              </Box>
            </Col>
          </Row>
        </Box>
      ) : (
        <Box className="property-risk-report mb-5">
          <section
            className={`admin-main-section`}
            style={{
              //  position: highRisk ? null : "relative",
              boxShadow: "0px 1px 0px 0px #DCE4EC,0px -1px 0px 0px #DCE4EC",
              // marginBottom: "150px",
            }}
          >
            <Container fluid className="">
              <Grid container alignItems="center">
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  className=""
                  order={{ xs: 2, sm: 2, md: 1 }}
                >
                  <Box className="property-image px-2 d-flex justify-content-center align-items-center section1">
                    <NewMap
                      className="map_section"
                      zoom={15}
                      center={{
                        lat: parseFloat(
                          propertyData?.data?.latitude || 41.363114
                        ), //41.363114
                        lng: parseFloat(
                          propertyData?.data?.longitude || -74.186442
                        ), //-74.186442
                      }}
                      coordinates={[]}
                      streetView={true}
                    />
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  className="mt-2"
                  order={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Box className="property-details position-relative px-2">
                    <Typography
                      variant="body1"
                      className="personal_info text-capitalize mb-2"
                    >
                      Personal information
                    </Typography>
                    <Typography variant="h5" className="property_add mb-2">
                      {propertyData?.data?.address || "-"}{" "}
                      {propertyData?.data?.unit_no &&
                        `#${propertyData?.data?.unit_no}`}
                    </Typography>

                    <Typography variant="body2" className="proeprty_date my-2">
                      Last checked on{" "}
                      {propertyData?.data?.last_checked_date || "-"}
                    </Typography>
                    <Box className="subscribe_btn">
                      <Button
                        className={`admin_monitoring_button `}
                        variant="contained"
                        onClick={() => {
                          setSubscribeModal(true);
                        }}
                      >
                        Start Free Monitoring
                      </Button>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3.5}
                  xl={3.5}
                  className="border-left"
                  order={{ xs: 3, sm: 3 }}
                >
                  <Box className="d-flex justify-content-center align-items-center h-75">
                    {riskManagementLoader ? (
                      <RiskoMeterChart chart_data={propertyData?.data} />
                    ) : (
                      <RiskoMeterChart chart_data={propertyData?.data} />
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={2.5}
                  xl={2.5}
                  className="mt-2"
                  order={{ xs: 4, sm: 4 }}
                >
                  <Box className="property-details position-relative">
                    <Typography variant="body1" className="value_text">
                      A value
                    </Typography>
                    <Typography variant="body1" className="values">
                      {`${currencyFormatter(
                        propertyData?.data?.approx_price || 0
                      )}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <p className="text-center text-danger pb-4 m-0 mt-2 fw-500 alert_msg_spacing">
                {proeprtyStatus === "Low"
                  ? "EquityProtect recommend our FREE monitoring service as your home recived a low risk assessment score."
                  : "EquityProtect strongly recommends our Premium Preventative Home Title Protection service due to your homes elevated risk  of Home Title Theft."}{" "}
                {propertyData?.data?.contact_support
                  ? propertyData?.message
                  : null}
              </p>
            </Container>

            <Container
              fluid
              className="warning-container admin-warning-container"
            >
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={`text-center analytics_section ${proeprtyStatus?.toLowerCase()}`}
                  style={{}}
                >
                  <div className="analytics_div">
                    <p className="m-0">
                      Our analytics indicate that your property is currently
                      classified as{" "}
                      <span className="text-uppercase">{proeprtyStatus}</span>{" "}
                      risk for fraud. This assessment is based on a variety of
                      factors that have been identified through our extensive
                      analysis.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Box>
      )}

      {/* Free Monitoring modals */}
      {subscribeModal && (
        <AlertDialog
          type={"Attention"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => setSubscribeModal(false)}
          mesage={
            <>
              Are you sure you want to start free monitoring for{" "}
              <b>{propertyData?.data?.address}</b> ?
            </>
          }
          buttons={
            <>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={subscribeFreeMonitor}
              >
                Yes
              </Button>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={() => setSubscribeModal(false)}
              >
                No
              </Button>
            </>
          }
        />
      )}

      {getObjLength(subscribeFreeMonitoring) ? (
        <AlertDialog
          type={subscribeFreeMonitoring?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            closeSubscribeModal("list_table");
          }}
          mesage={
            subscribeFreeMonitoring?.success
              ? subscribeFreeMonitoring?.message
              : subscribeFreeMonitoring?.data?.message || "Something went wrong"
          }
        />
      ) : null}
      <Loader open={riskManagementLoader} />
    </>
  );
};

export default AdminProeprtyRiskReport;
